import { FlowNode, LogicStep } from '../../types/flowTypes.ts';
import { calculateNodePositions } from '../position/calculateNodePositions.ts';
import { createLogicNode } from './createLogicNode.ts';

export const createNodesFromSlides = (slides: any[], logicSteps: LogicStep[] = []): FlowNode[] => {
  const positions = calculateNodePositions(slides);
  let nodes: FlowNode[] = [];

  // Create main slide nodes
  slides.forEach((slide) => {
    const position = positions.get(slide.id);
    
    nodes.push({
      id: slide.id,
      type: 'formNode',
      position: {
        x: position?.x || 0,
        y: position?.y || 0,
      },
      data: {
        id: slide.id,
        label: slide.slideName
      },
    });
  });

  // Count logic nodes per slide
  const logicCountPerSlide = new Map<string, number>();
  logicSteps.forEach(step => {
    logicCountPerSlide.set(step.step_id, (logicCountPerSlide.get(step.step_id) || 0) + 1);
  });

  // Create logic nodes with proper spacing
  const logicPositionTracker = new Map<string, number>();
  logicSteps.forEach((step, index) => {
    let logicRules = [];
    const sourcePosition = positions.get(step.step_id);
    if (sourcePosition) {
      const totalLogicForSlide = logicCountPerSlide.get(step.step_id) || 1;
      const currentLogicPosition = logicPositionTracker.get(step.step_id) || 0;


      if( step.logic_body) {
        step.logic_body.forEach((logicRule, index) => {
          // Create a unique node for each logic rule
          logicRules.push(logicRule)
        })
      }


        nodes.push(
        createLogicNode(
          step.step_id,
          index,
          sourcePosition.x,
          sourcePosition.y,
          currentLogicPosition,
          totalLogicForSlide,
          step.logic_body ? logicRules : null
        )
      );

      logicPositionTracker.set(step.step_id, currentLogicPosition + 1);
    }
  });

  return nodes;
};