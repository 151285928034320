import { FlowNode, LogicRule } from '../../types/flowTypes';

export const createLogicNode = (
    stepId: string,
    index: number,
    baseX: number,
    baseY: number,
    logicCount: number,
    totalLogic: number,
    logicRule?: any[]
): FlowNode => {
  const horizontalSpacing = 200;
  const totalWidth = (totalLogic - 1) * horizontalSpacing;
  const startX = baseX + 400;
  const xPosition = startX + (logicCount * horizontalSpacing);

  return {
    id: `L${index + 1}_${stepId}`,
    type: 'formNode',
    position: {
      x: xPosition,
      y: baseY,
    },
    data: {
      id: `L${index + 1}_${stepId}`,
      label: `Logic ${index + 1}`,
      isLogicNode: true,
      logicRule: logicRule
      // operation: logicRule ? logicRule[0].operation : '',
      // title_value: logicRule ? logicRule[0]?.title_value : '',
      // value: logicRule ? logicRule[0]?.value : '',
      // validationType: logicRule? logicRule[0]?.validationType : ''
    },
  };
};