import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";

const LogicTab = (props) => {
  const {
    logicType,
    addNewLogic,
    logicStepData,
    stepName,
    changeLogicsDefaultRedirect,
    arrayOfData,
    otherCasesRedirect,
    step,
    addRule,
    removeLogic,
    showConditions,
    handleLogicDetails,
    variables,
    customScripts = [],
    t,
  } = props;
  return (
    <div className="logic-fields ">
      {logicStepData.length ? (
        // conditions start
        <>
          <div
            style={{ width: "100%", maxHeight: "400px", overflowY: "scroll" }}
          >
            {logicStepData.map((logic, index) => {
              const {
                to,
                then,
                redirectUrl,
                mathOperation = "+",
                amount = 0,
                conditions,
                varValue = "",
              } = logic;
              return (
                <div
                  className="conditionsBox"
                  key={`logic__${step.id}__${index}`}
                >
                  {showConditions(step, logic, logicType, index)}
                  <div className="d-flex justify-content-around">
                    <div className="d-flex justify-content-around col-2">
                      <label>{t("leftBar-welcome-logicModal-then")} </label>
                    </div>
                    <div className="d-flex col-9 row">
                      <div className="col-4">
                        <select
                          value={then}
                          name="then"
                          className="form-control product-logic-input"
                          onChange={(e) =>
                            handleLogicDetails(e, index, logicType)
                          }
                        >
                          <optgroup label="Logic">
                            <option value="jump">
                              {t("leftBar-welcome-logicModal-jump-to")}
                            </option>
                            <option value="redirect">
                              {t("leftBar-welcome-logicModal-redirect-to")}
                            </option>
                            <option value="numeric">
                              {t("leftBar-welcome-logicModal-numeric")}
                            </option>
                            <option value="variable">
                              {t("leftBar-welcome-logicModal-variable")}
                            </option>
                            {logicType==="before" ? (
                                <option value="stay">
                                  {t("leftBar-welcome-logicModal-stay")}
                                </option>
                            ) : (
                                <></>
                            )}
                            {customScripts.length ? (
                              <option value="script">
                                {t("leftBar-welcome-logicModal-script")}
                              </option>
                            ) : (
                              <></>
                            )}
                          </optgroup>
                        </select>
                      </div>
                      {then === "jump" && (
                        <div className="col-8">
                          <select
                            value={to}
                            name="to"
                            className="form-control product-logic-input"
                            onChange={(e) => {
                              handleLogicDetails(e, index, logicType);
                            }}
                          >
                            {arrayOfData
                              .filter((itr) => itr.id !== step.id)
                              .map((itr, index) => {
                                if (!index) return;
                                return (
                                  <option
                                    key={`${itr.id}__${index}`}
                                    value={itr.id}
                                  >
                                    {`${index || "Start"}.`}
                                    {itr.slideName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      )}
                      {then === "numeric" && (
                        <>
                          <div className="col-2">
                            <select
                              value={mathOperation}
                              name="mathOperation"
                              className="form-control product-logic-input"
                              onChange={(e) =>
                                handleLogicDetails(e, index, logicType)
                              }
                            >
                              <option value="+">Add</option>
                              <option value="-">Subtract</option>
                              <option value="/">Division</option>
                              <option value="*">Multiplication</option>
                            </select>
                          </div>
                          <div className="col-2">
                            <input
                              type="number"
                              value={varValue}
                              className="form-control product-logic-input"
                              name="varValue"
                              onChange={(e) =>
                                handleLogicDetails(e, index, logicType)
                              }
                            />
                          </div>
                          <div className="col-1">
                            <label style={{ margin: "25px 5px" }}>To</label>
                          </div>
                          <div className="col-3">
                            <select
                              value={to}
                              name="to"
                              className="form-control product-logic-input"
                              onChange={(e) =>
                                handleLogicDetails(e, index, logicType)
                              }
                            >
                              <option disabled value="">
                                Choose variable
                              </option>
                              {variables
                                .filter((itr) => itr.type === "number")
                                .map((itr) => {
                                  if (itr.type === "number")
                                    return (
                                      <option
                                        key={`${itr.name}__${index}`}
                                        value={itr.name}
                                      >
                                        {itr.name}
                                      </option>
                                    );
                                })}
                            </select>
                          </div>
                        </>
                      )}

                      {/* --- */}

                      {then == "variable" && (
                        <>
                          <div className="col-1">
                            <label style={{ margin: "25px 5px" }}>set</label>
                          </div>
                          <div className="col-3">
                            <select
                              value={to}
                              name="to"
                              className="form-control product-logic-input"
                              onChange={(e) =>
                                handleLogicDetails(e, index, logicType)
                              }
                            >
                              <option disabled value="">
                                Choose variable
                              </option>
                              {variables
                                .filter((itr) => itr.type != "number")
                                .map((itr) => {
                                  return (
                                    <option
                                      key={`${itr.name}__${index}`}
                                      value={itr.name}
                                    >
                                      {itr.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-1">
                            <label style={{ margin: "25px 5px" }}>To</label>
                          </div>
                          <div className="col-3">
                            <input
                              style={{
                                margin: "12px 10px 10px 10px",
                                border: " 1px solid #d4d4d4",
                                height: "inherit",
                              }}
                              type="text"
                              value={varValue}
                              className="form-control product-logic-input"
                              name="varValue"
                              onChange={(e) =>
                                handleLogicDetails(e, index, logicType)
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* --- */}
                      {then === "redirect" && (
                        <div className="col-8">
                          <input
                            name="redirectUrl"
                            value={redirectUrl}
                            className="form-control product-logic-input"
                            onChange={(e) =>
                              handleLogicDetails(e, index, logicType)
                            }
                          />
                        </div>
                      )}
                      {then === "script" && (
                        <div className="col-8">
                          <select
                            value={to || customScripts[0].id}
                            name="to"
                            className="form-control product-logic-input"
                            onChange={(e) =>
                              handleLogicDetails(e, index, logicType)
                            }
                          >
                            <option disabled value="">
                              Choose script
                            </option>
                            {customScripts.map((itr) => {
                              return (
                                <option key={itr.id} value={itr.id}>
                                  {itr.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                    {conditions.length > 1 ? (
                      <div className="col-1 d-flex justify-content-between"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <a
                    title=""
                    className="add"
                    onClick={() => addRule(index, logicType)}
                  >
                    <span>+</span> {t("general-add")}
                  </a>
                  <div className="conditions-footer">
                    <a title="" className="down2"></a>
                    <a title="" className="up"></a>
                    <a
                      title=""
                      className="delete pointer"
                      onClick={() => removeLogic(index, logicType)}
                    >
                      {t("leftBar-welcome-logicModal-delete-condition")}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="cases">
            <label className="">
              {t("leftBar-welcome-logicModal-other-cases-jump-to")}
            </label>

            <select
              name="id"
              className="form-control w-75"
              value={otherCasesRedirect[logicType] || ""}
              onChange={(e) =>
                changeLogicsDefaultRedirect(e.target.value, logicType)
              }
            >
              <option value=""> {t("general-next")}</option>
              {arrayOfData.map((itr, index) => {
                if (itr.id === step.id && logicType!=="before") return;
                return (
                  <option value={(itr.id === step.id) ? "stay" : itr.id} key={`${itr.id}__${index}`}>
                    {`${index || "Start"}.`} {itr.slideName}
                    { (itr.id === step.id && logicType==="before") && " - Current Step"  }
                  </option>
                );
              })}
            </select>
          </div>
        </>
      ) : (
        // condition ends

        <div className="conditionsBox">
          <div className="row">
            <p className=" col-md-4 defualt-logic-title">
              {/*{t("leftBar-welcome-logicModal-q")} {step.counter}. {stepName}*/}
              {t("leftBar-welcome-allways-jump-to")}
            </p>
            <div className="col-6">
              <select
                name="id"
                className="form-control w-75"
                value={otherCasesRedirect[logicType] || ""}
                onChange={(e) => {
                  changeLogicsDefaultRedirect(e.target.value, logicType);
                }}
              >
                <option value="">{t("general-next")}</option>
                {arrayOfData.map((itr, index) => {
                  if (itr.id == step.id) return;
                  return (
                    <option value={itr.id} key={`${itr.id}__${index}`}>
                      {`${index || "Start"}.`} {itr.slideName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="conditions-footer">
            {/* <a title="" className="down2"></a>
            <a title="" className="up"></a>
            <a title="" className="delete">
              Delete condition
            </a> */}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end w-100">
        <a
          title=""
          className="addCondition"
          onClick={() => addNewLogic(logicType)}
        >
          {t("leftBar-welcome-logicModal-add-condition")}
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(LogicTab);
