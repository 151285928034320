import React from "react";
import { connect } from "react-redux";
import {
  getQuizDetailsById,
  getQuizDetailsByUserIdAndSlug,
  calculateLogicAction,
  resetLogicsResultAction,
  checkTriggersAction,
  setGtmStatusAction,
  setIsMobilePreview,
  togglePreviewDesignMode,
} from "../../redux/actions";
import ViewStep from "../CreateQuiz/View/ViewStep";
import { Helmet } from "react-helmet";
import GoogleFontLoader from "react-google-font";
import { Link } from "react-router-dom";
import { configHostUrl, websiteUrl } from "../../config";
import { withTranslation } from "react-i18next";
import { ReactComponent as KnowyLogo } from "../../images/knowy-logo-animation.svg";

import { stripTags, handleFinishQuiz } from "../../utils";
// import Avatar from "@material-ui/core/Avatar";
import Avatar from "@mui/joy/Avatar";

import { ImMobile } from "react-icons/im";
import { IoDesktopOutline } from "react-icons/io5";
import Accessibility from "./accessibillty";
import PoweredBy from "./poweredBy";
import "../CreateQuiz/Analyze/analyze.css";
import UnpublishedQuiz from "../UnpublishedQuiz";
import {
  CalculateProductLogics,
  ReduceAllProductsLogicHistory,
} from "../CreateQuiz/LeftBar/ProductsLogic/calculateProductLogics";
import "./transition.css";

class QuizView extends React.Component {
  state = {
    currentStep: 0,
    history: {
      back: null,
      next: null,
    },
    answers: [],
    contacts: {},
    numberOfCorrect: 0,
    numberOfIncorrect: 0,
    numberOfQuestions: 0,
    isCalculating: false,
    lastStepLoaded: "",
    score: 0,
    isEdit: false,
    viewMode: "desktop",
    showAccesibility: true,
    isResult: false,
    isExpired: false,
    allresdyAnswered: false,
    productsLogicsHistory: [],
    sliderAnimation: "roll-out-up",
  };

  componentDidMount() {
    const { state: quizId, pathname, search } = this.props.location;
    const { slug, userId, guid } = this.props.match.params;
    const isScreenShot = search.includes("screenShot=1");
    this.props.actions.setIsMobilePreview(false);
    const fullUrl = window.location.href;
    const subdomain = this.getSubdomain();
    this.props.actions.togglePreviewDesignMode(false);
    // this.props.actions.setGtmStatus("view");
    if (!quizId && !slug && !userId) {
      return this.props.history.goBack();
    }
    if (quizId) {
      this.props.actions.getQuizDetailsById(quizId);
      this.props.actions.setGtmStatus({ page: "view" });
      return;
    }

    if (slug && userId) {
      return this.props.actions.getQuizDetailsByUserIdAndSlug(
        userId,
        slug,
        "slug",
        isScreenShot,
        fullUrl
      );
    } else if (pathname.includes("/g/")) {
      if (guid && userId) {
        return this.props.actions.getQuizDetailsByUserIdAndSlug(
          userId,
          guid,
          "guid",
          isScreenShot,
          fullUrl
        );
      }
    } else if (pathname.includes("/page/") && subdomain) {
      if (!slug) {
        return this.props.history.goBack();
      }
      return this.props.actions.getQuizDetailsByUserIdAndSlug(
        subdomain,
        slug,
        "subdomain",
        isScreenShot,
        fullUrl
      );
    } else {
      return this.props.history.push("/");
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.state.isExpired) return;
    const isPreview =
      this.props.location && this.props.location.state ? true : false;
    if (!isPreview && !this.props.selectedDetails.status) {
      const subdomain = this.getSubdomain();
      if (
        this.props.selectedDetails.isLink &&
        this.props.selectedDetails.redirectLink
      ) {
        return (window.location.href = this.props.selectedDetails.redirectLink);
      }
      if (this.props.selectedDetails.redirectText) {
        this.setState({ ...this.state, isExpired: true });
        return;
      }

      if (subdomain) {
        return (window.location.href = `${websiteUrl}/login`);
      } else {
        return this.props.history.push("/dashboard");
      }
    }

    if (isPreview && !this.props.selectedDetails) return;
    let { id, published, variables, transitions } =
      this.props.selectedDetails.quizDetails;
    if (!transitions) {
      transitions = {
        useTransitions: false,
        transitionDirection: "moveUp",
        transitionDuration: 0.6,
      };
    }
    const { slug, userId, guid } = this.props.match.params;

    if (
      userId &&
      published != 1 &&
      !this.props.location.search.includes("screenShot=1")
    ) {
      return this.props.history.push("/");
    }
    if (id !== previousState.id) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      for (var variable in variables) {
        if (params[variables[variable].name])
          variables[variable].value = params[variables[variable].name];
      }
      const numberOfQuestions =
        this.props.selectedDetails.quizDetails.arrayOfData.filter(
          (step) => step.type !== "result"
        ).length;

      let allresdyAnswered = false;
      if (
        this.props.selectedDetails.quizDetails &&
        this.props.selectedDetails.quizDetails.duplications &&
        this.props.selectedDetails.quizDetails.duplications.noDuplicates
      ) {
        const knowyHistory = localStorage.getItem("KnowyHistory") || "{}";
        const ParsedHistory = JSON.parse(knowyHistory);
        if (ParsedHistory[this.props.selectedDetails.quizDetails.id]) {
          allresdyAnswered = true;
        }
      }
      this.setState({
        transitions,
        sliderAnimation: transitions.transitionDirection,
        ...this.props.selectedDetails.quizDetails,
        numberOfQuestions,
        allresdyAnswered,
      });
    }

    let { isCalculating, currentStep } = this.state;
    const { logicResult } = this.props;

    // go to logic if server responded and reset the state

    if (
      isCalculating &&
      logicResult &&
      previousProps.logicResult !== logicResult
    ) {
      if (logicResult.conditionsPosition === "after") {
        let {
          answers,
          currentStep,
          numberOfQuestions,
          arrayOfData,
          numberOfCorrect,
        } = this.state;
        let moveToStep = { before: currentStep, after: currentStep + 1 };
        if (answers.length < numberOfQuestions) {
          // if there is more questions left
          moveToStep = this.handleLogics("after");
        } else {
          const numberOfResults = arrayOfData.filter(
            (step) => step.type === "result"
          ); // number of results
          if (numberOfResults.length < 2) {
            // if there is one result page
            return currentStep + 1;
          }
          //   let relevantResultIndex = arrayOfData.findIndex(
          //     (step) =>
          //       step.type === "result" &&
          //       step.maxScore >= numberOfCorrect &&
          //       step.minScore <= numberOfCorrect
          //   );
          //   if (relevantResultIndex === -1) {
          //     moveToStep.after = currentStep + 1; // go the relevant result by score calculation
          //   } else {
          //     moveToStep.after = relevantResultIndex;
          //   }
        }
        if (moveToStep) {
          this.setState({
            currentStep: moveToStep.after,
            isCalculating: false,
          });
        } else {
          this.setState({ isCalculating: false });
        }
        this.props.actions.resetLogicsResult();
      }
    }
  }

  getSubdomain = () => {
    let subdomain;
    const host = window.location.host;
    if (!window.location.host.includes(configHostUrl)) {
      return window.location.host;
    }
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : 2);
    if (arr.length > 0) subdomain = arr[0];
    return !subdomain || subdomain === "app" ? null : subdomain;
  };

  stepBack = () => {
    if (this.props.isPreviewDesignMode) return;
    const {
      history,
      currentStep,
      answers,
      contacts,
      productsLogicsHistory = [],
      transitions = {},
    } = this.state;
    const { transitionDirection = "moveUp" } = transitions;
    const sliderAnimation =
      this.getOppositeTransitionDirection(transitionDirection);
    if (!history.back) return;
    const nextStep = {
      step: currentStep,
      back: history.back,
    };
    if (this.props.selectedDetails.quizDetails.type === "ecom") {
      const { stepId } = history.back;
      const productLogicIndexOfThisStep = productsLogicsHistory.findIndex(
        (logic) => logic.stepId.toLowerCase() === stepId.toLowerCase()
      );
      if (productLogicIndexOfThisStep !== -1) {
        productsLogicsHistory.splice(productLogicIndexOfThisStep, 1);
      }
    }

    this.setState({
      currentStep: history.back.step,
      history: { back: history.back.back, next: nextStep },
      productsLogicsHistory,
      sliderAnimation: sliderAnimation,
    });
  };

  saveAnswer = (arrayOfResult, isCorrect, questionId, answerScore = 0) => {
    let {
      answers,
      currentStep,
      numberOfCorrect,
      numberOfIncorrect,
      score,
      variables = [],
      contacts,
      history,
      arrayOfData,
      productsLogicsHistory = [],
      transitions = {},
    } = this.state;
    const { transitionDirection = "moveUp" } = transitions;
    const { productsLogics = {} } = this.props.selectedDetails.quizDetails;

    if (this.state.isCalculating || this.props.logicResult) {
      return;
    }

    if (arrayOfResult === "start" || arrayOfResult == "text") {
      CalculateProductLogics(
        arrayOfData[currentStep].id,
        { answers, variables, contacts, score },
        productsLogicsHistory,
        productsLogics[arrayOfData[currentStep].id]
      );
      this.handleNextStep();
      currentStep++;
      return this.setState({
        currentStep,
        history: {
          ...history,
          back:
            arrayOfResult === "start"
              ? { step: 0, stepType: "start", stepId: "start" }
              : history.back,
        },
        sliderAnimation: transitionDirection,
      });
    }
    const oldStep = {
      step: currentStep,
      stepType: "question",
      stepId: questionId,
      back: history.back,
    };

    const isAnsweredBefore = answers.findIndex(
      (itr) => itr.questionId == questionId
    );
    if (isAnsweredBefore != -1) {
      answers[isAnsweredBefore] = {
        arrayOfResult: [...JSON.parse(JSON.stringify(arrayOfResult))],
        isCorrect,
        questionId,
      };
    } else {
      score += answerScore;
      if (isCorrect) numberOfCorrect++;
      else numberOfIncorrect++;
      answers.push({
        arrayOfResult: JSON.parse(JSON.stringify(arrayOfResult)),

        isCorrect,
        questionId,
      });
    }
    if (arrayOfData.length > currentStep + 1) {
      CalculateProductLogics(
        arrayOfData[currentStep].id,
        { answers, variables, contacts, score },
        productsLogicsHistory,
        productsLogics[arrayOfData[currentStep].id]
      );
      currentStep = this.handleNextStep();
      if (currentStep) {
        this.setState({
          answers,
          currentStep,
          numberOfCorrect,
          numberOfIncorrect,
          score,
          history: { ...history, back: oldStep },
          sliderAnimation: transitionDirection,
        });
      } else {
        this.setState({
          answers,
          numberOfCorrect,
          numberOfIncorrect,
          score,
          history: { ...history, back: oldStep },
        });
      }
    }
  };

  getOppositeTransitionDirection = () => {
    const { transitions = {} } = this.state;
    const { transitionDirection = "moveUp" } = transitions;
    switch (transitionDirection) {
      case "moveUp":
        return "moveDown";
      case "moveDown":
        return "moveUp";
      case "moveLeft":
        return "moveRight";
      case "moveRight":
        return "moveLeft";
    }
  };

  saveContact = (stepId, contactResult) => {
    if (this.state.isCalculating) {
      return;
    }
    if (contactResult.other) {
    }
    let {
      currentStep,
      history,
      arrayOfData,
      contacts,
      answers,
      variables,
      score,
      productsLogicsHistory,
      transitions = {},
    } = this.state;

    const { transitionDirection = "moveUp" } = transitions;
    const { productsLogics = {}, type } = this.props.selectedDetails.quizDetails;

    const oldStep = {
      step: currentStep,
      stepType: "contact",
      stepId: stepId,
      back: history.back,
    };
    if (arrayOfData.length > currentStep) {
      Object.entries(contactResult).forEach(([key, value]) => {
        if (key === "other") {
          Object.entries(value).forEach(([key, value]) => {
            contacts[key] = value;
          });
        }
        {
          contacts[key] = value;
        }
      });

   if(type === "ecom"){

      CalculateProductLogics(
        arrayOfData[currentStep].id,
        { answers, variables, contacts, score },
        productsLogicsHistory,
        productsLogics[arrayOfData[currentStep].id]
      );

   }
      
      currentStep = this.handleNextStep();
      this.setState({
        currentStep,
        contacts,
        history: { ...history, back: oldStep },
        sliderAnimation: transitionDirection,
      });
    }
  };

  handleLogics = (conditionsPosition) => {
    let {
      answers,
      currentStep,
      arrayOfData,
      variables = [],
      contacts,
      score,
    } = this.state;

    const { isLogic } = this.props;
    const CurrStepId = arrayOfData[currentStep].id;

    const isLogicNeeded =
      isLogic[CurrStepId] && isLogic[CurrStepId][conditionsPosition];
    if (!arrayOfData[currentStep]) {
      return { after: currentStep, before: currentStep - 1 };
    }

    if (isLogicNeeded && !this.props.logicResult && !this.state.isCalculating) {
      this.props.actions.calculateLogic(
        this.props.selectedDetails.quizDetails.id,
        arrayOfData[currentStep].id,
        conditionsPosition,
        { answers, variables, contacts, score },
        this.props.visitKey
      );
      this.setState({ isCalculating: true });
      return { after: currentStep, before: currentStep };
    } else if (!isLogicNeeded) {
      return { after: currentStep + 1, before: currentStep };
    } else {
      const { method, target } = this.props.logicResult;
      let toReturn = { after: currentStep + 1, before: currentStep };
      if (method === "redirect") {
        window.location.href = target;
        return {};
      } else if (method === "numeric" || method === "variable") {
        const variableIndex = variables.findIndex((itr) => itr.name == target);
        if (variableIndex == -1) return toReturn;
        variables[variableIndex].value = this.props.logicResult.value;
        this.setState({ variables });
      } else if (method === "jump") {
        if (target==="stay") return toReturn;
        if (!target) return toReturn;

        let nextSection = arrayOfData.findIndex((itr) => itr.id == target);
        if (!nextSection) nextSection = currentStep + 1;
        toReturn = { after: nextSection, before: nextSection };
      } else if (method === "script") {
        toReturn = {
          after: currentStep + 1,
          before: currentStep,
          logicScriptToRun: target,
        };
      } else {
        toReturn = { after: currentStep + 1, before: currentStep };
      }
      return toReturn;
    }
  };

  moveToStepDesignMode = (e) => {
    const { value } = e.target;

    this.setState({ currentStep: parseInt(value) });
  };

  handleNextStep = () => {
    let {
      answers,
      currentStep,
      numberOfQuestions,
      arrayOfData,
      numberOfCorrect,
    } = this.state;
    const { isLogic } = this.props;
    const CurrStepId = arrayOfData[currentStep].id;
    const isLogicNeeded = isLogic[CurrStepId] && isLogic[CurrStepId].after;

    if (isLogicNeeded && currentStep < arrayOfData.length) {
      // if there is more questions left

      const moveToStep = this.handleLogics("after").after;
      return moveToStep;
    }
    const numberOfResults = arrayOfData.filter(
      (step) => step.type === "result"
    ); // number of results

    if (arrayOfData[currentStep + 1].type !== "result") return currentStep + 1; // if it is not the last step
    if (numberOfResults.length < 2) {
      // if there is one result page
      return currentStep + 1;
    }

    let relevantResultIndex = arrayOfData.findIndex(
      (step) =>
        step.type === "result" &&
        step.maxScore >= numberOfCorrect &&
        step.minScore <= numberOfCorrect
    );
    if (relevantResultIndex === -1) return currentStep + 1; // go the relevant result by score calculation
    return relevantResultIndex;
  };

  handleSteps = (step, index, style) => {
    const { type, logic, id: stepId } = step;
    const { logicResult } = this.props;
    const {
      isCalculating,
      lastStepLoaded,
      arrayOfData,
      currentStep,
      productsLogicsHistory = [],
    } = this.state;
    const { isLogic } = this.props;
    const CurrStepId = arrayOfData[currentStep].id;
    const isLogicNeeded = isLogic[CurrStepId] && isLogic[CurrStepId].after;
    if (
      isLogicNeeded &&
      logicResult &&
      logicResult.conditionsPosition == "after"
    ) {
      this.handleNextStep();
    }

    if (isCalculating && logicResult) {
      let moveToStep = this.handleLogics(logicResult.conditionsPosition);
      this.props.actions.resetLogicsResult();
      this.setState({
        currentStep: moveToStep[logicResult.conditionsPosition],
        isCalculating: false,
        logicScriptToRun: moveToStep.logicScriptToRun,
      });
    }

    if (lastStepLoaded !== stepId) {
      this.handleLogics("before");
      this.setState({ lastStepLoaded: stepId });
    }
    switch (type) {
      case "question": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            isPreview={true}
            saveAnswer={this.saveAnswer}
            stepBack={this.stepBack}
            stepIndex={index}
            isEdit={this.state.isEdit}
            style={style}
            stepType={"question"}
            isCalculating={isCalculating}
            customScripts={this.state.customScripts}
            sliderAnimation={this.state.sliderAnimation}
          />
        );
      }
      case "result": {
        if (!this.state.onEdit && !this.state.isResult) {
          this.props.actions.checkTriggers(this.state.id, this.props.visitKey);
          handleFinishQuiz(this.state.id, true);
          const calculatedProducts = ReduceAllProductsLogicHistory(
            productsLogicsHistory,
            this.props.selectedDetails.quizDetails.feedProducts
          );
          this.setState({ isResult: true, calculatedProducts });
        }
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            stepType={"result"}
            isPreview={true}
            stepBack={this.stepBack}
            saveAnswer={this.saveAnswer}
            stepIndex={index}
            isEdit={this.state.isEdit}
            style={style}
            isCalculating={isCalculating}
            customScripts={this.state.customScripts}
            calculatedProducts={this.state.calculatedProducts || []}
            sliderAnimation={this.state.sliderAnimation}
          />
        );
      }
      case "contact": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            isPreview={true}
            saveContact={this.saveContact}
            stepBack={this.stepBack}
            stepIndex={index}
            isEdit={this.state.isEdit}
            style={style}
            stepType={"contact"}
            isCalculating={isCalculating}
            customScripts={this.state.customScripts}
            sliderAnimation={this.state.sliderAnimation}
          />
        );
      }
      case "start": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            isPreview={true}
            stepBack={this.stepBack}
            saveAnswer={this.saveAnswer}
            stepIndex={index}
            isEdit={this.state.isEdit}
            style={style}
            stepType={"start"}
            isCalculating={isCalculating}
            customScripts={this.state.customScripts}
            sliderAnimation={this.state.sliderAnimation}
          />
        );
      }
      case "text": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            isPreview={true}
            stepBack={this.stepBack}
            saveAnswer={this.saveAnswer}
            stepIndex={index}
            isEdit={this.state.isEdit}
            style={style}
            stepType={"text"}
            isCalculating={isCalculating}
            sliderAnimation={this.state.sliderAnimation}
          />
        );
      }
    }
  };

  changeViewMode = (value) => {
    this.setState({ viewMode: value });
  };

  resetPreview = () => {
    this.setState({
      currentStep: 0,
      answers: [],
      numberOfCorrect: 0,
      numberOfIncorrect: 0,
      score: 0,
    });
  };

  render() {
    let {
      id,
      title,
      arrayOfData,
      settings,
      editedAt,
      fonts,
      direction,
      shareDetails,
      customScripts,
      customStyle,
      answers,
      viewMode,
      isExpired,
      removeBranding,
      currentStep,
      allresdyAnswered,
      productsLogicsHistory,
    } = this.state;
    const preview = this.props.location.state ? true : false;
    customScripts = customScripts ? customScripts : {};
    if (isExpired || allresdyAnswered) {
      return (
        <>
          <UnpublishedQuiz allresdyAnswered={allresdyAnswered} />
        </>
      );
    }
    if (!id)
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "#F2F1FF" }}
        >
          <div style={{ display: "block" }}>
            <KnowyLogo style={{ width: "250px" }} />
            <div
              style={{
                display: "block",
                color: "#264FA2",
                paddingTop: "20px",
                textAlign: "center",
              }}
            >
              <h1>Loading...</h1>
            </div>
            <div
              className="h-100 d-flex align-items-center justify-content-center"
              style={{ paddingTop: "25px" }}
            >
              <h2>Powered by Knowy</h2>
            </div>
          </div>
        </div>
      );
    //<h1>Loader..</h1>;
    let {
      backgroundColor,
      backgroundImage,
      backgroundSize,
      backgroundPosition,
      backgroundColorType,
      backgroundOverlayColor,
      backgroundOverlayOpacity,
      backgroundMediaType,
      backgroundVideoSrc,
      gradientColors,
      gradientType,
      gradientDeg,
    } = settings;
    const stringOfColors = Object.values(gradientColors).toString();
    let style = {
      // background: `${gradientType}(${gradientDeg}, ${stringOfColors})`
      backgroundColor,
    };
    const {
      title: shareTitle,
      description: shareDescription,
      imageUrl,
    } = shareDetails;
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>{this.state.seo.title}</title>
          <link rel="shortcut icon" href={`${this.state.favicon}?v=2`} />
          <link
            rel="icon"
            type="image/x-icon"
            href={`${preview ? imageUrl : "%PUBLIC_URL%/favicon.png"}`}
          />
          <meta
            id="meta-description"
            name="description"
            content={`${this.state.seo.description}`}
          />

          <meta property="og:title" content={shareTitle} />
          <meta property="og:description" content={shareDescription} />
          <meta property="og:image" content={imageUrl} />
          <style>{customStyle}</style>
          {Object.values(customScripts).map((itr) => {
            if (
              itr.active &&
              itr.runtime == 1 &&
              itr.script.includes("<script>") &&
              itr.script.includes("</script>")
            ) {
              const newScript = itr.script
                .replaceAll("<script>", "")
                .replaceAll("</script>", "");
              return <script>{newScript}</script>;
            } else if (
              itr.active &&
              itr.runtime == 1 &&
              itr.script.includes("<noscript>") &&
              itr.script.includes("</noscript>")
            ) {
              const newScript = itr.script
                .replaceAll("<noscript>", "")
                .replaceAll("</noscript>", "");
              return <noscript>{newScript}</noscript>;
            }
          })}
        </Helmet>
        <div className={`App ${direction}`}>
          <GoogleFontLoader fonts={fonts} />
          {preview ? (
            <div className="resolution-preview">
              {this.props.isPreviewDesignMode ? (
                <div className="steps-dropdown" style={{ marginRight: "20px" }}>
                  <select
                    name=""
                    onChange={this.moveToStepDesignMode}
                    value={currentStep}
                  >
                    {arrayOfData.map((step, index) => {
                      return (
                        <option key={step.id} value={index}>
                          {step.slideName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <></>
              )}

              <button
                style={{
                  marginRight: "20px",
                  backgroundColor: "#F2F1FF",
                  color: "#0046B5",
                  fontSize: "14px",
                }}
                className={`btn ${
                  this.props.isPreviewDesignMode ? "btn-dark" : ""
                }`}
                onClick={() => {
                  if (this.props.isPreviewDesignMode) this.resetPreview();
                  this.props.actions.togglePreviewDesignMode(
                    !this.props.isPreviewDesignMode
                  );
                }}
              >
                Design Mode
              </button>
              <div className="sortBtns">
                <span
                  // onClick={() => setPreviewResolution("mobile")}
                  onClick={() => {
                    this.changeViewMode("mobile");
                    this.props.actions.setIsMobilePreview(true);
                  }}
                  style={{
                    background:
                      this.state.viewMode == "mobile" ? "#FFFFFF" : "",
                    filter:
                      this.state.viewMode == "mobile"
                        ? "drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.08))"
                        : "",
                  }}

                  // className={displayType == "grid" ? "active" : ""}
                >
                  {/*<img src="img/icon-gallery.png" />*/}
                  <ImMobile
                    type="button"
                    onClick={() => {
                      this.changeViewMode("mobile");
                      this.props.actions.setIsMobilePreview(true);
                    }}
                    color={
                      this.state.viewMode == "mobile" ? "#0046B5" : "#78889f"
                    }
                    size={18}
                  />
                </span>
                <span
                  onClick={() => {
                    this.changeViewMode("desktop");
                    this.props.actions.setIsMobilePreview(false);
                  }}
                  style={{
                    background:
                      this.state.viewMode == "desktop" ? "#FFFFFF" : "",
                    filter:
                      this.state.viewMode == "desktop"
                        ? "drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.08))"
                        : "",
                  }}
                >
                  <IoDesktopOutline
                    type="button"
                    onClick={() => {
                      this.changeViewMode("desktop");
                      this.props.actions.setIsMobilePreview(false);
                    }}
                    color={
                      this.state.viewMode == "desktop" ? "#0046B5" : "#78889f"
                    }
                    size={18}
                  />
                </span>
              </div>

              <button
                style={{
                  marginLeft: "20px",
                  backgroundColor: "#F2F1FF",
                  color: "#0046B5",
                  fontSize: "14px",
                }}
                className="btn"
                onClick={() => this.resetPreview()}
              >
                {t("Restart-preview")}
              </button>
              <Link
                style={{
                  marginLeft: "10px",
                  paddingTop: "8px",
                  backgroundColor: "#be1eb9",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                className="btn"
                to={`/edit/${id}`}
              >
                {t("Back-to-edit!")}
              </Link>
            </div>
          ) : (
            <></>
          )}
          <div
            className={
              this.state.viewMode == "desktop" ? `` : `viewContainer mobile`
            }
            style={{ height: "100%" }}
          >
            {arrayOfData.map((step, index) => {
              if (
                backgroundMediaType === "image" ||
                backgroundColorType === "color"
              ) {
                if (index === 0) {
                  style = {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    background: `${gradientType}(${gradientDeg}, ${stringOfColors})`,
                    backgroundSize,
                    backgroundPosition,
                    backgroundOverlayColor,
                    backgroundOverlayOpacity,
                    display: "flow-root",
                    backgroundMediaType: "image",
                    backgroundColor,
                  };
                } else {
                  style = {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize,
                    backgroundPosition,
                    backgroundOverlayColor,
                    backgroundOverlayOpacity,
                    background: `${gradientType}(${gradientDeg}, ${stringOfColors})`,
                    // height: "100%",
                    // minHeight: "100vh",
                    display: "flow-root",
                    backgroundMediaType: "image",
                    backgroundColor,
                  };
                }
              }

              if (backgroundMediaType === "video") {
                if (index === 0) {
                  style = {
                    backgroundImage: backgroundImage,
                    backgroundRepeat: "no-repeat",
                    background: `${gradientType}(${gradientDeg}, ${stringOfColors})`,
                    backgroundSize,
                    backgroundPosition,
                    backgroundOverlayColor,
                    backgroundOverlayOpacity,
                    // height: "100%",
                    // minHeight: "100vh",
                    display: "flow-root",
                    backgroundColor,
                    backgroundMediaType: "video",
                    backgroundVideoSrc: backgroundVideoSrc,
                  };
                } else {
                  style = {
                    backgroundImage: backgroundImage,
                    backgroundRepeat: "no-repeat",
                    backgroundSize,
                    backgroundPosition,
                    backgroundOverlayColor,
                    backgroundOverlayOpacity,
                    background: `${gradientType}(${gradientDeg}, ${stringOfColors})`,
                    // height: "100%",
                    // minHeight: "100vh",
                    display: "flow-root",
                    backgroundColor,
                    backgroundMediaType: "video",
                    backgroundVideoSrc: backgroundVideoSrc,
                  };
                }
              }

              if (index === this.state.currentStep) {
                return (
                  <div key={step.id} style={{ height: "100%" }} id={step.id}>
                    <div style={{ height: "100%" }}>
                      {this.handleSteps(step, index, style)}
                    </div>
                  </div>
                );
              }
            })}
            <div className="accessibility">
              <Accessibility quizId={id} />

              {!removeBranding ? (
                <>
                  <PoweredBy />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    saveQuizMessage,
    selectedDetails,
    accessibility,
    logicResult,
    isLogic,
    visitKey,
    isPreviewDesignMode,
  } = state;
  return {
    saveQuizMessage,
    selectedDetails,
    accessibility,
    logicResult,
    isLogic,
    visitKey,
    isPreviewDesignMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getQuizDetailsById: (quizId) => dispatch(getQuizDetailsById(quizId)),
      getQuizDetailsByUserIdAndSlug: (
        userId,
        slug,
        handler,
        isScreenShot,
        fullUrl
      ) =>
        dispatch(
          getQuizDetailsByUserIdAndSlug(
            userId,
            slug,
            handler,
            isScreenShot,
            fullUrl
          )
        ),
      calculateLogic: (
        quizId,
        stepId,
        conditionsPosition,
        quizData,
        visitKey
      ) =>
        dispatch(
          calculateLogicAction(
            quizId,
            stepId,
            conditionsPosition,
            quizData,
            visitKey
          )
        ),
      resetLogicsResult: () => dispatch(resetLogicsResultAction()),
      checkTriggers: (quizId, visitKey) =>
        dispatch(checkTriggersAction(quizId, visitKey)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
      setIsMobilePreview: (isMobilePreview) =>
        dispatch(setIsMobilePreview(isMobilePreview)),
      togglePreviewDesignMode: (isActive) =>
        dispatch(togglePreviewDesignMode(isActive)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(QuizView)
);
