import { useCallback, useEffect } from "react";
import { ReactFlow, Background, Controls, Connection, Edge } from "@xyflow/react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import { fetchFormBuilderData } from "./../services/formBuilderService.ts";
import { createNodesFromSlides, createEdgesFromLogicSteps } from "./../services/flowService.ts";
import FlowNode from "./flow/FlowNode.tsx";
import FlowEdge from "./flow/FlowEdge.tsx";
import "@xyflow/react/dist/style.css";

const nodeTypes = {
  formNode: FlowNode,
};

const edgeTypes = {
  customEdge: FlowEdge,
};

const IntegrationCanvas = (props) => {

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  console.log(props.data.id)
  const { data: formData, isLoading, error } = useQuery({
    queryKey: ['formBuilder',props.data.id],
    queryFn: ()=>fetchFormBuilderData(props.data.id),
    retry: 2,
    staleTime: 0.5 * 60 * 1000, // Consider data fresh for 5 minutes
  });

  useEffect(() => {
    console.log("formData",formData)

    if (formData) {
      try {
        const newNodes = createNodesFromSlides(formData.slides, formData.logic_steps);
        const newEdges = createEdgesFromLogicSteps(formData.logic_steps, formData.slides);

        setNodes(newNodes);
        setEdges(newEdges);
        console.log(newNodes)
        toast.success("Form data loaded successfully!");
      } catch (err) {
        console.error('Error processing form data:', err);
        toast.error("Error processing form data");
      }
    }
  }, [formData, setNodes, setEdges]);

  const onConnect = useCallback(
    (params: Connection | Edge) => {
      setEdges((eds) => [...eds, { ...params, type: 'customEdge' }]);
      toast.success("Connection created successfully!");
    },
    [setEdges]
  );

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-red-50">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error Loading Form Data</h2>
          <p className="text-gray-600">{error instanceof Error ? error.message : 'Unknown error occurred'}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto mb-4"></div>
          <p className="text-gray-600">Loading form data...</p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100vh" }} className="w-full h-screen bg-canvas-bg">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        className="bg-canvas-bg"
      >
        <Background gap={20} color="#e2e8f0" />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default IntegrationCanvas;