import { NodePosition } from '../../types/flowTypes';

export const calculateNodePositions = (slides: any[]): Map<string, NodePosition> => {
  const nodePositions = new Map<string, NodePosition>();
  const verticalSpacing = 150;
  const startX = 250;
  const horizontalSpacing = 400;
  let currentY = 0;
  let resultNodeCount = 0;

  slides.forEach((slide, index) => {
    const isResultNode = slide.id.startsWith('R_');
    
    if (isResultNode) {
      nodePositions.set(slide.id, {
        x: startX + (resultNodeCount * horizontalSpacing),
        y: currentY,
        row: index
      });
      resultNodeCount++;
    } else {
      nodePositions.set(slide.id, {
        x: startX,
        y: currentY,
        row: index
      });
      currentY += verticalSpacing;
    }
  });

  return nodePositions;
};