import { withAuth } from "./auth";
import Register from "../components/Register";
import React from "react";
import QuizView from "../components/QuizView";
import CreateQuiz from "../components/CreateQuiz";
import QuizesList from "../components/Dashboard";
import Templates from "../components/TemplatesList";
import Login from "../components/Login";
import Home from "../components/Home";
import NotFound from "../components/ErrorPage";
import AccountPage from "../components/Account";
import ResetPassword from "../components/resetPassword";
import Embedd from "../components/Embedd";
import Subscription from "../components/Subscriptions";
import UnpublishedQuiz from "../components/UnpublishedQuiz";
import Organiztion from "../components/Organization/Index";
import Integrations from "../components/Integrations";
import IntegrationCanvas from "../components/IntegrationCanvas.tsx";
export const guestRoutes = [
  {
    exact: true,
    isVisible: false,
    title: "QuizSubdomain",
    path: "/page/:slug",
    component: QuizView,
  },
  {
    exact: true,
    isVisible: false,
    title: "QuizSlug",
    path: "/quiz/:userId/:slug",
    component: QuizView,
  },
  {
    exact: true,
    isVisible: false,
    title: "QuizGuid",
    path: "/quiz/:userId/g/:guid",
    component: QuizView,
  },
  {
    exact: true,
    isVisible: false,
    title: "Preview",
    path: "/preview",
    component: QuizView,
  },
  {
    exact: true,
    isVisible: false,
    title: "Sorry",
    path: "/sorry",
    component: UnpublishedQuiz,
  },
  { exact: true, isVisible: false, title: "Home", path: "/", component: Home },
  // { path: "*", isVisible: false, component: NotFound, title: "lala" },
];

export const userRoutes = [
  {
    exact: true,
    isVisible: false,
    title: "Register",
    path: "/register",
    component: (props) => {
      // props.defaultTab = "register";
      let newProps = { ...props, defaultTab: "register" };
      return <Login {...newProps} />;
    },
  },
  {
    exact: true,
    isVisible: false,
    title: "Reset Password",
    path: "/resetPassword",
    component: ResetPassword,
  },
  {
    exact: true,
    isVisible: false,
    title: "Create New Quiz",
    path: "/edit/:quizId",
    component: (props) => {
      const CreateQuizWithAuth = withAuth(CreateQuiz);
      return <CreateQuizWithAuth {...props} />;
    },
  },
  // {
  //   exact: true,
  //   isVisible: false,
  //   title: "Create New Quiz",
  //   path: "/edit/:quizId/:wsId",
  //   component: (props) => {
  //     const CreateQuizWithAuth = withAuth(CreateQuiz);
  //     return <CreateQuizWithAuth {...props} />;
  //   },
  // },
  {
    exact: true,
    isVisible: true,
    title: "Account",
    path: "/account",
    component: (props) => {
      const AccountPageWithAuth = withAuth(AccountPage);
      return <AccountPageWithAuth {...props} />;
    },
  },
  {
    exact: true,
    isVisible: true,
    title: "My Quizes",
    path: "/dashboard",
    component: (props) => {
      const QuizesListWithAuth = withAuth(QuizesList);
      return <QuizesListWithAuth {...props} />;
    },
  },
  {
    exact: true,
    isVisible: true,
    title: "My Quizes",
    path: "/logics",
    component: (props) => {
      // const QuizesListWithAuth = withAuth(QuizesList);
      return <IntegrationCanvas />
       // <QuizesListWithAuth {...props} />;
    },
  },
  {
    exact: true,
    isVisible: false,
    title: "Templates",
    path: "/templates",
    component: (props) => {
      const TemplatesWithAuth = withAuth(Templates);
      return <TemplatesWithAuth {...props} />;
    },
  },

  {
    exact: true,
    isVisible: true,
    title: "Integrations",
    path: "/integrations",
    component: (props) => {
      return <Integrations {...props} />;
    },
  },

  {
    exact: true,
    isVisible: false,
    title: "Embedd",
    path: "/embedd",
    component: (props) => {
      return <Embedd {...props} />;
    },
  },
  {
    exact: true,
    isVisible: true,
    title: "profile",
    path: "/profile",
    component: (props) => {
      return <AccountPage {...props} />;
    },
  },
  {
    exact: true,
    isVisible: true,
    title: "subscriptions",
    path: "/subscriptions",
    component: (props) => {
      return <Subscription {...props} />;
    },
  },
  {
    exact: true,
    isVisible: true,
    title: "Organization",
    path: "/organization",
    component: (props) => {
      return <Organiztion {...props} />;
    },
  },

];
