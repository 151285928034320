import React from "react";
import "./style.css";
import { Link, Redirect } from "react-router-dom";
import EditTitle from "../EditTitle";
import PublishModal from "../PublishModal";
import { AppLinks } from "../../../appRouter/appRouter";
import { userRoutes } from "../../../appRouter/router.config";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { AiOutlineSave, AiOutlineEye } from "react-icons/ai";
import { IoSaveOutline, IoEyeOutline } from "react-icons/io5";

import {
  /*   changeLanguage,
   */ logout,
  createSubdomainAction,
  changeEmbeddObject,
} from "../../../redux/actions";
import { withTranslation } from "react-i18next";
/* import i18n from "../../../translate/i18n";
 */ import NavBarModal from "./navBarModal";
import { FiEye, FiSave } from "react-icons/fi";
import ExitWithoutSaveModal from "./ExitWithoutSaveModal";

class NavBar extends React.Component {
  /*  handleLanguage = (e) => {
    const { value } = e.target;
    this.props.actions.changeLanguage(value);
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);
  }; */

  state = {
    toShow: false,
    showExitWithoutSavingModal: false,
    functionAfterSavingModal: null,
    redirectInstanly: false,
    redirectToLogin: false,
  };

  modalTogle = (val) => {
    this.setState({ toShow: val });
  };

  closeExitWithoutSavingModal = () => {
    this.setState({ showExitWithoutSavingModal: false });
  };

  openExitWithoutSavingModal = (functionAfterSavingModal = () => {}) => {
    if (!this.props.data.changed) {
      this.setState({ redirectInstanly: true, functionAfterSavingModal });
    } else {
      this.setState({
        showExitWithoutSavingModal: true,
        functionAfterSavingModal,
      });
    }
  };

  callbackWhenSwitchOrganization = () => {
    this.setState({ redirectToLogin: true });
  };

  render() {
    const { published, slug, id, guid, mode } = this.props.data;
    const { userId, t, orgId } = this.props;
    const {
      redirectInstanly,
      functionAfterSavingModal,
      showExitWithoutSavingModal,
      redirectToLogin,
    } = this.state;
    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }
    if (redirectInstanly && functionAfterSavingModal) {
      return functionAfterSavingModal();
    }

    return (
      <header>
        <ExitWithoutSaveModal
          saveQuiz={this.props.editQuiz}
          isOpen={showExitWithoutSavingModal}
          afterFunction={functionAfterSavingModal}
          closeModal={this.closeExitWithoutSavingModal}
        />
        <PublishModal
          orgId={orgId}
          url={document.location.href}
          published={published}
          userId={userId}
          quizId={id}
          slug={slug}
          guid={guid}
          publishQuiz={this.props.publishQuiz}
          createSubdomain={this.props.actions.createSubdomain}
          errors={this.props.errors}
          changeEmbeddObject={this.props.actions.changeEmbeddObject}
          showModal={this.state.toShow}
          changeShowModal={this.modalTogle}
          changeMode={this.props.changeMode}
        />

        <div className="logo">
          <img
            onClick={() => {
              return this.openExitWithoutSavingModal(() => (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                  }}
                />
              ));
            }}
            src="/img/logo-inner.png"
            alt="KNOWY - KNOW YOUR CLIENT"
            style={{ maxHeight: "45px", cursor: "pointer" }}
          />
        </div>
        <div className="quiz-name">
          <EditTitle
            title={this.props.data.title}
            changeTitle={this.props.changeTitle}
          />
          <div>
            <a role="button" title="edit"></a>
            <div
              style={{
                background: "#0046B5",
                height: "2px",
              }}
            ></div>
          </div>
        </div>
          <div className="quiz-mainNav">
              <a
                  role="button"
                  onClick={() => this.props.changeMode("build")}
                  title={t("nav-build")}
                  className={mode === "build" ? "active" : ""}
              >
                  {t("nav-build")}
              </a>
              <a
                  role="button"
                  onClick={() => this.props.changeMode("logic")}
                  title={t("nav-logic")}
                  className={mode === "logic" ? "active" : ""}
              >
                  {t("nav-logic")}
              </a>
              <a
                  type="button"
                  onClick={() => this.props.changeMode("configure")}
                  title={t("nav-configuration")}
                  className={mode === "configure" ? "active" : ""}
              >
                  {t("nav-configuration")}
              </a>
              <a
                  role="button"
                  onClick={() => this.props.changeMode("connect")}
                  title={t("nav-connect")}
                  className={mode === "connect" ? "active" : ""}
              >
                  {t("nav-connect")}
              </a>
              <a
                  role="button"
                  onClick={() => this.props.changeMode("share")}
                  title={t("nav-share")}
                  className={mode === "share" ? "active" : ""}
              >
                  {t("nav-share")}
              </a>
              <a
                  type="button"
                  onClick={() => this.props.changeMode("analyze")}
                  title={t("nav-analyze")}
                  className={mode === "analyze" ? "active" : ""}
              >
                  {t("nav-analyze")}
              </a>
          </div>
          {/*  <div>
          <select onChange={this.handleLanguage}>
            <option value="en">English</option>
            <option value="he">Hebrew</option>
          </select>
        </div> */}

          <IoSaveOutline title="Save" size={26} onClick={this.props.editQuiz} />
        {/* <a href="#" title="save" className="save"></a> */}
        <span
          onClick={() => {
            return this.openExitWithoutSavingModal(() => (
              <Redirect
                to={{
                  pathname: "/preview",
                  state: this.props.selectedDetails.quizDetails.id,
                }}
              />
            ));
          }}
        >
          {/*<div type="button" title="view" className="viwe"></div>*/}
          <IoEyeOutline title="View" size={30} />
        </span>

        {published != 1 ? (
          <a
            className="publish"
            data-bs-toggle="modal"
            data-bs-target="#publish"
            type="button"
            title="Publish"
            style={{ cursor: "pointer" }}
            onClick={() => this.modalTogle(true)}
          >
            {t("nav-publish")}
          </a>
        ) : (
          <a
            className="published"
            data-toggle="modal"
            data-target="#publish"
            type="button"
            title="Publish"
            style={{ cursor: "pointer" }}
            onClick={() => this.modalTogle(true)}
          >
            {t("nav-published")}
          </a>
        )}
        <NavBarModal
          openExitWithoutSavingModal={this.openExitWithoutSavingModal}
          callbackWhenSwitchOrganization={this.callbackWhenSwitchOrganization}
        />
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  let { name, orgId } = state.loginDetails;
  const { errors } = state;
  return { name, errors, orgId };
};
const mapDispatchToprops = (dispatch) => {
  return {
    actions: {
      logout: () => dispatch(logout()),
      /*  changeLanguage: (language) => dispatch(changeLanguage(language)), */
      createSubdomain: (name, quizId) =>
        dispatch(createSubdomainAction(name, quizId)),
      changeEmbeddObject: (obj) => dispatch(changeEmbeddObject(obj)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToprops)(NavBar)
);
