import { LogicStep } from './types/flowTypes';

const createSequentialEdge = (sourceId: string, targetId: string) => ({
  id: `sequential-${sourceId}-${targetId}`,
  source: sourceId,
  target: targetId,
  sourceHandle: 'sequential-source',
  targetHandle: 'sequential-target',
  type: 'customEdge',
  animated: true,
  label: 'Next',
  style: { stroke: '#2563eb' },
});

const createLogicEdge = (sourceId: string, logicNodeId: string) => ({
  id: `logic-${sourceId}-${logicNodeId}`,
  source: sourceId,
  target: logicNodeId,
  sourceHandle: 'logic-source',
  targetHandle: 'logic-target',
  type: 'customEdge',
  animated: true,
  label: 'Logic',
  style: { stroke: '#f59e0b' },
});

const createLogicTargetEdge = (logicNodeId: string, targetId: string) => ({
  id: `logic-target-${logicNodeId}-${targetId}`,
  source: logicNodeId,
  target: targetId,
  sourceHandle: 'logic-source',
  targetHandle: 'sequential-target',
  type: 'customEdge',
  animated: true,
  label: 'Then',
  style: { stroke: '#f59e0b' },
});

export const createEdgesFromLogicSteps = (logicSteps: LogicStep[], slides: any[]) => {
  const edges: any[] = [];
  
  // Create sequential connections between slides, excluding connections between result nodes
  slides.forEach((slide, index) => {
    if (index < slides.length - 1) {
      const currentId = slide.id;
      const nextId = slides[index + 1].id;

      // Check if current slide has a logic step with null logic_body
      const hasNullLogic = logicSteps.some(step =>
          step.step_id === currentId && step.logic_body === null
      );

      // Only create sequential edge if there's no null logic and not connecting between results
      if (!hasNullLogic && !(currentId.startsWith('R_') && nextId.startsWith('R_'))) {
        edges.push(createSequentialEdge(currentId, nextId));
      }
      //
      // // Skip creating sequential edge if both nodes are result nodes
      // if (!(currentId.startsWith('R_') && nextId.startsWith('R_'))) {
      //   edges.push(createSequentialEdge(currentId, nextId));
      // }
    }
  });

  // Create logical connections
  logicSteps.forEach((step, index) => {
    const logicNodeId = `L${index + 1}_${step.step_id}`;
    
    // Create edge from source slide to logic node
    edges.push(createLogicEdge(step.step_id, logicNodeId));
    
    // Create edge from logic node to target slide if target exists
    if (step.logic_action_target) {
      edges.push(createLogicTargetEdge(logicNodeId, step.logic_action_target));
    }
  });

  return edges;
};