import React, { Component } from "react";
// import './App.css';
import "./main.css";
import i18n from "./translate/i18n";
import Modal from "react-bootstrap/esm/Modal";

import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  useSearchParams,
} from "react-router-dom";
import Login from "./components/Login";
import { AppRoutes } from "./appRouter/appRouter";
import { userRoutes, guestRoutes } from "./appRouter/router.config";
import { init, changeLanguage } from "./redux/actions";
import { connect } from "react-redux";
import CheckMediaQuery from "./utils/CheckMediaQuery";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

class App extends Component {
  state = {
    isShowModal: false,
    modalMsg: "",
    tagManagerArgs: null,
    isGtm: false,
    innerPages: ["connect", "analyze", "config"],
  };


  componentWillMount() {
    this.handleSubdomain();
    const token = localStorage.getItem("token") || "";
    if (!token) return;
    this.props.actions.init();
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
      changeLanguage(language);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("stripe-success");

    if (term === "true") {
      this.setState({
        isShowModal: true,
        modalMsg: "Your payment was successful",
      });
    } else if (term === "false") {
      this.setState({ isShowModal: true, modalMsg: "Your payment failed" });
    }
  }

  handleSubdomain = () => {
    const subdomain = this.getSubdomain();
    const path = window.location.pathname;
    if (!path.includes("/page/") && subdomain) {
      const newPath = window.location.href.replace(`${subdomain}.`, "");
      // <Redirect to={window.location.href.replace(`${subdomain}.`, "")} />
      window.location.href = newPath;
      return;
    }
  };

  getSubdomain = () => {
    let subdomain;
    const host = window.location.host;
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : 2);
    if (arr.length > 0) subdomain = arr[0];
    return !subdomain || subdomain === "app" ? null : subdomain;
  };




render() {
    const {
      lowSaturation,
      highSaturation,
      monochrome,
      highContrast,
      fontFamilyAriel,
    } = this.props.accessibility;

    return (
      <div
        style={{
          direction: i18n.language == "he" ? "rtl" : "ltr",
          height: "100%",
        }}
        className={`App ${
          lowSaturation
            ? "lowSaturation"
            : highSaturation
            ? "highSaturation"
            : monochrome
            ? "monochrome"
            : highContrast
            ? "highContrast"
            : fontFamilyAriel
            ? "fontFamilyAriel"
            : ""
        }`}
      >
        <CheckMediaQuery />
        <BrowserRouter>

          <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/login" component={Login} />
            {/* <Route component={NotFound} /> */}
            <AppRoutes routes={[...userRoutes, ...guestRoutes]} />
          </Switch>
          </QueryClientProvider>
        </BrowserRouter>
        <Modal
          className="package-modal"
          size="m"
          show={this.state.isShowModal}
          onHide={() => {
            this.setState({ isShowModal: false, modalMsg: "" });
            window.location.href =
              window.location.href.split("?")[0] || window.location.href;
          }}
          centered
        >
          <h2>{this.state.modalMsg}</h2>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { accessibility } = state;
  return { accessibility };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      init: () => dispatch(init()),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
