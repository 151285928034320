import { Handle, Position } from "@xyflow/react";
import { FileText } from "lucide-react";

interface FlowNodeProps {
  data: {
    id: string;
    label: string;
    isLogicNode?: boolean;
    logicRule?: any[];
    // operation?: string;
    // title_value?: string;
    // value?: string;
    // validationType?: string;
  };
}

const FlowNode = ({ data }: FlowNodeProps) => {
  const isQuestion = data.id.startsWith("Q_");
  const isResult = data.id.startsWith("R_");
  const isCondition = data.id.startsWith("C_");
  const isLogic = data.id.startsWith("L");

  const getNodeColor = () => {
    if (isLogic) return 'bg-orange-500';
    if (isQuestion || isCondition) return 'bg-purple-500';
    if (isResult) return 'bg-green-500';
    return 'bg-blue-500';
  };

  function removeHTMLTags(htmlString) {
    if (!htmlString) return '';
    return htmlString.replace(/<[^>]*>/g, '');
  }

  const renderLogicContent = () => {
    if (!isLogic) return null;
    return (
        <div className="flex flex-col gap-1 mt-2 text-xs">
          {!data.logicRule && (
            <div>Always jump to</div>
          )}

          {data.logicRule &&
              data.logicRule.map((logicRule, index) => {
                // Return a unique node for each logic rule
                return (

                    <div key={index}>


                      {index > 0 && (
                          <div className="text-gray-600">   {logicRule.operator && (logicRule.operator )} </div>
                      )}

                      {logicRule.validationType === "Variables" && logicRule.sectionId && (
                          <span className="text-gray-600"> {logicRule.sectionId}
              </span>
                      )}


                      {logicRule.operation && (
                          <span className="text-gray-600">
               {(logicRule.validationType === "Questions"  || logicRule.validationType === "Form Answers") && (<span>Answer:</span>)} {logicRule.operation}
              </span>
                      )}

                      {(logicRule.validationType === "Questions") && logicRule.title_value && (
                          <span className="text-gray-600"> {removeHTMLTags(logicRule.title_value)}
              </span>
                      )}


                      {(logicRule.validationType === "Form Answers" || logicRule.validationType === "Variables") && logicRule.value && (
                          <span className="text-gray-600"> {logicRule.value}</span>
                      )}
                    </div>
                );
              })}
        </div>
    );
  };

  return (
    <div style={{backgroundColor:"white"}} className={`bg-canvas-node rounded-lg shadow-lg p-4 ${isLogic ? 'min-w-150' : 'min-w-200'}`}>
      {!isLogic && (
        <Handle 
          type="target" 
          position={Position.Top} 
          className="w-3 h-3 bg-blue-500"
          id="sequential-target"
        />
      )}
      
      {!isLogic && (
        <Handle 
          type="source" 
          position={Position.Right} 
          className="w-3 h-3 bg-orange-500"
          id="logic-source"
          style={{right: -15}}
        />
      )}

      {isLogic && (
        <Handle 
          type="target" 
          position={Position.Left} 
          className="w-3 h-3 bg-orange-500"
          id="logic-target"
          style={{left: -15}}
        />
      )}
      
      <div className="flex items-center gap-3">
        <div className={`p-2 rounded-lg ${getNodeColor()}`}>
          <FileText className="w-6 h-6 text-white" />
        </div>
        <div className="flex flex-col">
          <span className="font-medium text-gray-700">{data.label}</span>
          {/*<span className="text-sm text-gray-500">{data.id}</span>*/}
        </div>
      </div>

      {renderLogicContent()}

      {isLogic && (
        <Handle 
          type="source" 
          position={Position.Right} 
          className="w-3 h-3 bg-orange-500"
          id="logic-source"
          style={{ right: -15 }}
        />
      )}
      
      {!isLogic && (
        <Handle 
          type="source" 
          position={Position.Bottom} 
          className="w-3 h-3 bg-blue-500"
          id="sequential-source"
        />
      )}
    </div>
  );
};

export default FlowNode;